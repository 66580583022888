/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-04-26 14:06:29
 */

// import { api } from './service.js'
import { service } from './service.js'

const server = {
	eget(req) {
		return service.get('/express/eget', req)
	},
	epost(req) {
		return service.post('/express/epost', req)
	},
	erate(req) {
		return service.post('/express/rate', req)
	},


	getNews(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/external/news/detail`, req)
	},
	getList(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/external/news/list`, req)
	},
}
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}
	}

	return null
}

function download(apk, name) {
	// // 创建隐藏的可下载链接
	if (apk.includes('www.gbwhatsapp.download')) {
		apk = apk.replace('www.gbwhatsapp.download', 'cdn.gbwhatsapp.download')
	}

	var eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)

	// eleLink.setAttribute('download', name)
	eleLink.style.display = 'none'
	// eleLink.setAttribute('target', '_blank')
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		var r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function formatNum(num) {
	if (num) {
		return num.toString().replace(/\B(?=(?:\d{3})+\b)/g, ',')
	} else {
		return 0
	}
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	return fmt
}

function img_loading() {
	//初始化需要显示的图片，并且指定显示的位置
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var imglist = document.getElementsByClassName('loadimg')
		// console.log(imglist);
		for (var i = 0; i < imglist.length; i++) {
			let tt = imglist[i]
			// console.log(tt);
			// console.log(tt.loading);
			//防止重复加载
			if (tt.loading == true) {
				continue
			}
			//没有该属性代表不加载
			if (!tt.getAttribute('src-data')) {
				continue
			}
			tt.loading = true
			Imagess(tt.getAttribute('src-error') ? tt.getAttribute('src-error') : tt.src, tt.getAttribute('src-data'), (obj) => {
				tt.src = obj.src
				tt.removeAttribute('src-data')
			})
		}
	} 
}

function Imagess(errorimg, url, callback, error) {
	//判断是否加载完成
	var val = url
	var img = new Image()
	img.onload = () => {
		// window.hide();
		if (img.complete == true) {
			callback(img)
		}
	}
	//如果因为网络或图片的原因发生异常，则显示该图片
	if (error) {
		img.onerror = error
	} else {
		img.onerror = function() {
			img.src = errorimg
		}
	}
	img.src = val
}

function formatTimestamp(timestamp) {
	var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	var date = new Date(timestamp)
	var month = months[date.getMonth()]
	var day = date.getDate()
	var year = date.getFullYear()
	return month + ' ' + day + ',' + year
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatNum,
	formatDate,
	img_loading,
	Imagess,
	server,
	download,
	formatTimestamp,
}
